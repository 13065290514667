// MainLayout.js
import React from 'react';
import { Layout, Flex, Image, Typography,Button } from 'antd';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { UserOutlined } from '@ant-design/icons';
import './MainLayout.css';

const { Text } = Typography;
const { Header, Footer, Content } = Layout;

const headerStyle = {
  textAlign: 'center',
  color: '#fff',
  height: 72,
  paddingInline: 80,
  backgroundColor: '#060042',
  display: 'flex', 
  alignItems: 'center',
  justifyContent: 'space-between', 
};

const footerStyle = {
  color: '#fff',
  backgroundColor: '#060042',
  height: 119,
  paddingInline: 80,
  borderTop: '1px solid #949494',
  display: 'flex', 
  alignItems: 'center',
  justifyContent: 'space-between', 
};

const mStyle = {
  fontWeight: '500',
  fontSize: 16,
  color: '#FFFFFF',
};

const MainLayout = ({ children }) => {

  const navigate = useNavigate();

  return (
    <Layout>
      <Header className="responsive-header" style={headerStyle}>
        <Flex align="center" justify="space-between" style={{ width: '100%', flexDirection: window.innerWidth <= 768 ? 'column' : 'row' }}>
          <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
            <Image
              src="/images/logo.png"
              alt="Logo"
              preview={false}
              width={150}
              style={{ display: 'block' }}
            />
          </Link>
          {/*<Flex
            className="responsive-nav"
            gap="middle"
            style={{
              height: 72,
              borderBottom: 'none',
              flex: 1,
              backgroundColor: '#060042',
              justifyContent: 'center',
              alignItems: 'center', 
            }}
          >
            <Link
              href=""
              target="_blank"
              rel="noopener noreferrer"
              style={{
                ...mStyle, 
                display: 'inline-block', 
                lineHeight: '1.5', 
                whiteSpace: 'normal', 
                pointerEvents: 'none'
              }}
            >
              About iRetain
            </Link> 

            <Link
              href="#"
              onClick={() => {
                window.location.href = "mailto:support@iretain.eu?subject=Support Request&body=Hello, I need assistance with...";
              }}
              style={{
                ...mStyle, 
                display: 'inline-block', 
                lineHeight: '1.5', 
                whiteSpace: 'normal'
              }}
            >
              Contact Us
            </Link>

          </Flex>*/}

          <Flex className="responsive-buttons" gap="middle">

            <Button
              className="responsive-button"
              onClick={() => navigate('/register')}
              style={{
                border: 0,
                borderRadius: 70,
                backgroundColor: '#B1B1B1',
                height: 44,
                width: 147,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
               {/* <div
                  style={{
                    backgroundColor: 'white',
                    padding: 2,
                    borderRadius: 100,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: 10,
                  }}
                >
                  <UserOutlined
                    style={{
                      color: '#EF82D1',
                      fontSize: 24,
                    }}
                  /> 
                </div>*/}
                <span style={mStyle}>Register</span>
            </Button>

            <Button
              className="responsive-button"
              style={{
                border: 0,
                borderRadius: 70,
                backgroundColor: '#64A7E6',
                height: 44,
                width: 147,
              }}
            >
              <a
                href="/iretain_doctor/#/home"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                }}
              >
                <span style={mStyle}>Login</span>
              </a>
            </Button>
          </Flex>
        </Flex>
      </Header>

      <Content>{children}</Content>

      <Footer className="responsive-footer" style={footerStyle}>
        <Flex
          justify="space-between" 
          align="center" 
          style={{
            height: '100%', 
            width: '100%',
            flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
          }}
        >

          <Text
            className="responsive-footer-text"
            style={{
              fontWeight: '500',
              fontSize: 14,
              color: '#FFFFFF',
              lineHeight: '119px', 
            }}
          >
            &copy; {new Date().getFullYear()} iRetain, Inc. - All Rights Reserved.
          </Text>

          <Text
            className="responsive-footer-text"
            style={{
              fontWeight: '500',
              fontSize: 14,
              color: '#FFFFFF',
              lineHeight: '119px', 
            }}
          >
            Contact Us: support@iretain.ai
          </Text>

          {/*<Flex gap={64}>
            <Link
              href=""
              target="_blank"
              rel="noopener noreferrer"
              style={{
                pointerEvents: 'none',
                fontWeight: '500',
                fontSize: 14,
                color: '#FFFFFF',
                lineHeight: '119px',
              }}
            >
              Terms of use
            </Link>
            <Link
              href=""
              target="_blank"
              rel="noopener noreferrer"
              style={{
                pointerEvents: 'none',
                fontWeight: '500',
                fontSize: 14,
                color: '#FFFFFF',
                lineHeight: '119px', 
              }}
            >
              Privacy policy
            </Link>
          </Flex>  */}
        </Flex>
      </Footer>
    </Layout>
  );
};

export default MainLayout;